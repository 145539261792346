const portolio = [
    {
        name: "Punto Ian Cowork",
        url: "https://puntoian.com.ar",
        image: "punto-ian.png",
        technologies: "React",
        repository: "https://github.com/mativiscusso/punto-ian",
    },
    {
        name: "Grupo Lipari S.A.",
        url: "https://grupolipari.com.ar",
        image: "grupo-lipari.png",
        technologies: "HTML, CSS, JS Vanilla",
        repository: "https://github.com/mativiscusso/landing-grupo-lipari",
    },
    {
        name: "Club BANCO",
        url: "https://club-banco.com",
        image: "clubbanco.png",
        technologies: "HTML, CSS, JS Vanilla",
        repository: "https://github.com/mativiscusso/clubbanco",
    },
    {
        name: "Dashboard E-commerce",
        url: "http://ecommerce-starter-mev.herokuapp.com/admin",
        image: "dashboard-mev.png",
        technologies: "Node JS, Express, EJS",
        repository: "https://github.com/mativiscusso/ecommerce-node",
    },
    {
        name: "IAN Digital Talent",
        url: "https://www.iandigitaltalent.com",
        image: "acheyl.png",
        technologies: "HTML, SCSS, JavaScript",
        repository: "https://github.com/dmy1983/ian-digital-talent",
    },
    {
        name: "Somos marea",
        url: "https://www.somosmarea.com",
        image: "marea.png",
        technologies: "HTML, CSS, JavaScript",
        repository: "https://github.com/mativiscusso/somosmarea",
    },
    {
        name: "GitHub - API Axios",
        url: "https://github.com/mativiscusso/API-Axios",
        image: "github.png",
        technologies: "Node JS, Express",
        repository: "https://github.com/mativiscusso/API-Axios",
    },
    {
        name: "UVC Soluciones",
        url: "https://www.uvcsoluciones.com",
        image: "uvc.png",
        technologies: "HTML, SCSS, JavaScript",
        repository: "https://github.com/mativiscusso/UVC_Soluciones",
    },
    {
        name: "Keclon",
        url: "http://www.keclon.com",
        image: "keclon.png",
        technologies: "HTML, SCSS",
        repository: "https://github.com/mativiscusso/keclon",
    },
    {
        name: "Asesor contable online",
        url: "https://www.asesorcontableonline.com",
        image: "asesor.png",
        technologies: "HTML, CSS, JavaScript, PHP",
        repository: "https://github.com/mativiscusso/asesor-contable-online",
    },
    {
        name: "Ache & L",
        url: "http://www.acheyl.com.ar",
        image: "acheyl.png",
        technologies: "HTML, CSS, JavaScript",
        repository: "https://github.com/mativiscusso/acheyl",
    },
    {
        name: "GitHub - Juego Memoria",
        url: "https://github.com/mativiscusso/juego-de-memoria",
        image: "github.png",
        technologies: "HTML, CSS, JavaScript",
        repository: "https://github.com/mativiscusso/juego-de-memoriavg",
    },
    {
        name: "Consultorios Belgrano",
        url: "https://www.consultoriobelgrano.com.ar",
        image: "belgrano.png",
        technologies: "HTML, CSS, JavaScript",
        repository: "https://github.com/mativiscusso/consultoriosBelgrano",
    },
    {
        name: "GitHub - SimpleCart JS",
        url: "https://github.com/mativiscusso/carritoJS",
        image: "github.png",
        technologies: "JavaScript",
        repository: "https://github.com/mativiscusso/carritoJS",
    },
    {
        name: "GitHub - Integracion MP",
        url: "https://github.com/mativiscusso/node_integracion_mp",
        image: "github.png",
        technologies: "Node JS, Express",
        repository: "https://github.com/mativiscusso/node_integracion_mp",
    },
];

export default portolio;
